viewopenReport<template>
  <!-- v-loading.fullscreen.lock="loading" -->
  <div class="risk user-box">
    <search-form @query="query" @search="searchList" ref="child"></search-form>
    <query-table :list="list" @view="openReport" @del="delFriend"></query-table>
    <!-- 分页管理 -->
    <el-pagination background :page-sizes="pageSizes" :page-size="10" :current-page="currentPage"
      layout="total, sizes, prev, pager, next" :total="page.allNum" @size-change="changeSize"
      @current-change="changePage"></el-pagination>
  </div>
</template>

<script>
import searchForm from './components/search-form';
import queryTable from './components/query-table';

import service from './api';
import pagination from '@/mixins/pagination';
import getReports from '@/mixins/getReports';
import { Message } from "element-ui";

export default {
  mixins: [pagination, getReports],
  data() {
    return {
      list: [],
      page: {}
    };
  },
  components: {
    searchForm,
    queryTable
  },
  methods: {
    init() {
      let initParams = { pageNum: 1 };
      return this.getList(initParams);
    },
    // 获取查询列表
    getList(params) {
      // 切换当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;

      return service.getList(params).then(res => {
        let { list = [], page = {} } = res;
        list.forEach((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
        });
        this.list = list;
        this.page = page;
      });
    },
    // 查询用户
    query(data) {
      return service.directQuery(data).then(res => {
        if (res.data !== '') {
          Message.warning(res);
        } else {
          Message.success("查询成功！")
        }
        this.getReports();
        this.init();
        this.$refs.child.clear();
      });
    },
    // 搜索列表
    searchList(data) {
      let params = { username: data.name, mobile: data.mobile, idcard: data.idcard };
      this.searchData = params;
      return this.getList({ ...params, pageNum: 1 });
    },
    handleClose() {
      this.setShowView(false);
      this.getList({ pageNum: this.currentPage, pageSize: this.pageSize });
    },
    openReport(row) {
      if (!row.verify_status.real_name) {
        // 未实名认证不可查看报告
        this.$notify({
          type: "error",
          message: "用户未实名认证，暂不能查看用户报告！",
          offset: 400
        });
        return;
      }
      // 打开报告界面
      const { href } = this.$router.resolve({
        path: `/risk/detail`,
        query: { flId: row.flId }
      });
      window.open(href, '_blank');
    },
    // 删除好友
    delFriend(row) {
      this.$alert('确认删除该用户？','提示',{showCancelButton:true}).then(() => {
        return service.delFriend({
          id: row.flId
        }).then(res => {
          this.$notify({ message: '删除成功！', type: 'success', offset: 400, duration: 800 });
          this.init();
        });
      }).catch()
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 480px) {
    .el-message-box{
      width: 80%!important;
      background: #000;
    }
  }
</style>
